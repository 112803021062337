import { IdsMainButton } from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import '../../final-screen.scss'

const Cards = () => {
  const proposalContext = useProposal()
  const link = proposalContext?.product?.proposal?.link
  const productCode = proposalContext?.product?.proposal?.productCode

  return (
    <Layout>
      <div className="final_screen">
        <div className="final_screen__content">
          <h1 className="final_screen__title">
            Acesse a página para pedir o seu cartão gratuitamente
          </h1>

          <div className="final_screen__buttons-wrapper">
            <a href={link} target="_blank" rel="noreferrer">
              <IdsMainButton
                full={true}
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'telas-finais-de-redirect-do-produto',
                    detail: productCode
                  })
                }}
              >
                Acessar a página
              </IdsMainButton>
            </a>
            <SeeOffersButton isCrossSell />

            <p className="final_screen__disclaimer">
              *Proposta sujeita à análise de crédito
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Cards
